<template>
    <v-row no-gutters align="center" justify="center">
        <v-col cols="12" class="text-center">
            <div class="section">
                <h1>Billetterie en ligne</h1>
            </div>
            <v-container v-if="isPrepared">
                <v-col cols='12' class="text-center">
                    <v-card outlined>
                        <v-card-title id="card-title">
                            Réservez vos places pour la soirée d'ouverture de {{ $announcedEvent.nom }}
                        </v-card-title>
                    </v-card>
                    <v-alert type="info" class="mt-4" v-if="isStarted">
                        <v-row align="center">
                            <v-col class="grow">
                                Un problème sur cette page ? Merci d'essayer directement chez notre prestataire de
                                billetterie:
                            </v-col>
                            <v-col class="shrink">
                                <v-btn :href="outsideUrl" color="orange">je commande mes billets</v-btn>
                            </v-col>
                        </v-row>
                    </v-alert>
                </v-col>
            </v-container>
            <v-alert type="info" v-else>La mise en ligne des billets pour {{ $announcedEvent.nom }} n'est pas encore
                prévue
            </v-alert>
            <v-container fluid class="pa-0 ma-0">
                <iframe
                        v-if="isStarted"
                        id="haWidget"
                        allowtransparency="true"
                        :src="$announcedEvent.ticketing.url"
                        style="width:100%;height:800px;border:none;"
                ></iframe>
                <v-alert type="info" v-else>La mise en ligne des billets est prévu pour le {{ dateInFrench }}</v-alert>
            </v-container>
            <v-alert type="info" class="mt-4" v-if="isStarted">
                <v-row align="center">
                    <v-col class="grow">
                        Un problème sur cette page ? Merci d'essayer directement chez notre prestataire de
                        billetterie:
                    </v-col>
                    <v-col class="shrink">
                        <v-btn :href="outsideUrl" color="orange">je commande mes billets</v-btn>
                    </v-col>
                </v-row>
            </v-alert>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "Reservation",
    data: function () {
        return {}
    },
    computed: {
        isStarted() {
            return new Date() > new Date(this.$announcedEvent.ticketing.startDate)
        },
        isPrepared() {
            return this.$announcedEvent.ticketing && this.$announcedEvent.ticketing.startDate != null;
        },
        dateInFrench() {
            const options = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};
            return new Date(this.$announcedEvent.ticketing.startDate).toLocaleDateString('fr-FR', options)
        },
        outsideUrl() {
            return this.isPrepared ? this.$announcedEvent.ticketing.url.replace("/widget", "") : "";
        }
    },
};
</script>

<style lang="scss" scoped>
$font: "Roboto", sans-serif;
$font-titre: "Roboto Condensed", sans-serif;
$orange: #f1662c;
$bleu: #18b3e2;

.section {
  margin-top: 30px;
  margin-bottom: 30px;
}

main {
  h1 {
    font-family: $font-titre;
    font-weight: bold;
    color: black;
  }

  .v-card__title {
    font-family: $font-titre;
    font-weight: bold;
    justify-content: center;
    color: ghostwhite;
    background-color: $bleu;
    word-break: break-word;
  }

  .v-card__text {
    font-family: $font;
    background-color: #eeeeee;

    span {
      color: $orange;
      font-weight: bold;
    }
  }
}
</style>
